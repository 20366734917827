import React from 'react';
import {Link} from 'gatsby';
import styled from 'styled-components';
import theme from '../utils/theme';

const List = styled.ul`
  border-left: 1px solid rgba(38, 38, 44, 0.15);
  padding-left: 14px;
  margin-bottom: 32px;

  li:first-child {
    padding-top: 8px;
  }

  li:last-child a {
    padding-bottom: 8px;
    margin-bottom: 0;
  }
`;

const Item = styled.li`
  font-weight: ${({bold}) => (bold ? 'bold' : 400)};
  margin-bottom: 16px;
  margin-left: ${({bold}) => (bold ? 0 : 12)}px;

  a {
    font-size: 16px !important;
  }
`;

const DemoLink = styled(Link)`
  display: inline-block !important;
  padding: 7px 11px 10px;
  font-family: 'Lato', sans-serif;
  font-size: 16px !important;
  font-weight: bold;
  border: 2px solid ${theme.primaryLight};
  border-radius: 7px;
  margin-bottom: 12px !important;
  transition: 100ms;

  &:hover {
    color: ${theme.textWhite};
    background: ${theme.primaryLight};
  }
  &:active {
    background: ${theme.primaryClick};
    border-color: ${theme.primaryClick};
  }
`;

export default ({headings, pageLink, demoLink}) => {
  const getHash = value =>
    value
      .split(' ')
      .join('-')
      .toLowerCase();

  return (
    <>
      {!!demoLink && <DemoLink to={demoLink}>Try it out</DemoLink>}
      <List>
        {headings.map(({depth, value}) => {
          return (
            <Item key={value} bold={depth < 3}>
              <Link to={`${pageLink}#${getHash(value)}`}>{value}</Link>
            </Item>
          );
        })}
      </List>
    </>
  );
};
