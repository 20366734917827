import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Sidenav from './sidenav';
import './layout.css';

const Content = styled.div`
  margin-left: 382px;

  @media (max-width: 1439px) {
    margin-left: 0;
  }
`;

const Layout = ({children}) => (
  <>
    <Sidenav />
    <Content>{children}</Content>
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
