import React from 'react';
import styled from 'styled-components';
import Layout from '../components/layout';
import {Link, graphql} from 'gatsby';
import theme from '../utils/theme';
import map from '../../static/images/404_page/404_map.svg';
import marker from '../../static/images/404_page/red_marker.svg';
import SEO from '../components/seo';
import * as PropTypes from 'prop-types';

const Section = styled.section`
  width: 100%;
  margin: 140px auto 120px;
  position: relative;

  img {
    display: block;
    margin: 0 auto;
  }

  .marker {
    position: absolute;
    top: 190px;
    left: calc(50% - 15px);
    display: block;
    z-index: 3;
  }

  @media (max-width: 767px) {
    height: 460px;

    background: linear-gradient(
      180deg,
      #ffffff 0%,
      rgba(255, 255, 255, 0.79) 39.64%,
      rgba(255, 255, 255, 0) 100%
    );

    img:first-child {
      width: 100%;
      height: 180px;
      position: absolute;
      top: 200px;
    }
    .marker {
      top: 252px;
      width: 30px;
      height: 38px;
    }
  }
`;

const ImageOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background: linear-gradient(white, transparent 300px);
  z-index: 0;
  pointer-events: none;
`;

const TextContent = styled.div`
  position: absolute;
  top: -50px;
  left: calc(50% - 184px);
  width: 368px;
  height: 200px;
  padding: 42px 0;
  border-radius: 6px;
  box-shadow: 0 10px 80px -10px rgba(155, 189, 202, 0.6);
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  h1 {
    font-size: ${({headerSize}) => `${headerSize}px`};
    line-height: ${({headerSize}) => `${headerSize}px`};
  }

  p {
    margin: 0;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: -15px;
    left: calc(50% - 18px);
    display: block;
    height: 36px;
    width: 36px;
    background: white;
    border-radius: 6px;
    transform: rotate(45deg);
    z-index: 0;
  }

  @media (max-width: 767px) {
    h1 {
      font-size: 60px;
    }
    width: 280px;
    left: calc(50% - 140px);
    top: 20px;
  }
`;

const GhostButton = styled(Link)`
  display: block;
  width: 180px;
  margin: 40px auto;
  text-decoration: none;
  padding: 14px 24px 18px;
  background: ${theme.textWhite};
  border: 2px solid ${theme.primaryLight};
  border-radius: 27px;
  text-align: center;
  transition: 100ms;
  z-index: 2;

  &:hover {
    background: ${theme.primaryLight};
    color: ${theme.textWhite};
  }

  &:active {
    background: ${theme.primaryClick};
  }

  @media (max-width: 767px) {
    position: absolute;
    top: 380px;
    left: calc(50% - 90px);
  }
`;

const ErrorPage = ({data, headerSize = 100}) => {
  const html = data.allMarkdownRemark.edges[0].node.html;
  return (
    <Layout>
      <SEO title="404" />
      <Section>
        <img src={map} alt="Section of a map" />
        <ImageOverlay />
        <img src={marker} alt="Map marker" className="marker" />
        <TextContent headerSize={headerSize} dangerouslySetInnerHTML={{__html: html}} />
        <GhostButton to="/">Go back home</GhostButton>
      </Section>
    </Layout>
  );
};

ErrorPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.object),
    }),
  }).isRequired,
};

export default ErrorPage;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(filter: {frontmatter: {id: {eq: "404"}}}) {
      edges {
        node {
          html
        }
      }
    }
  }
`;
