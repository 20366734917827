import React from 'react';
import {graphql, Link, StaticQuery, withPrefix} from 'gatsby';
import PropTypes from 'prop-types';
import {Location} from '@reach/router';
import styled from 'styled-components';
import theme from '../utils/theme';
import logo from '../../static/images/geodatahub_for_developers.svg';
import logoWhite from '../../static/images/geodatahub_for_developers_white.svg';
import Submenu from './submenu';
import {windowObject} from '../utils/helpers';

const Container = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  top: 0;
  width: 382px;
  padding: 30px 60px;
  background: ${theme.primaryBlue};
  border-right: 1px solid rgba(38, 38, 44, 0.2);

  ul {
    margin-left: 12px;
  }

  a {
    text-decoration: none;
    font-size: 20px;
    margin-bottom: 20px;
    display: block;
  }

  .single-link {
    margin-bottom: 40px;
  }

  .close-button {
    position: absolute;
    top: 28px;
    right: 36px;
  }

  @media (min-width: 1440px) {
    .close-button {
      display: none;
    }
  }

  @media (max-width: 1439px) {
    width: 100vw;
    background: ${theme.textWhite};
    z-index: 11;
    padding: 14px 20px;
  }
`;

const Logo = styled(Link)`
  width: 160px;
  &:before {
    content: url(${({src}) => src});
  }
`;

const MenuList = styled.ul`
  height: calc(100vh - 140px);
  overflow: auto;

  &::-webkit-scrollbar {
    width: 3px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${theme.primaryLight};
  }
  &::-webkit-scrollbar-track {
    background: linear-gradient(
      to right,
      transparent 1px,
      rgba(38, 38, 44, 0.25) 2px,
      transparent 2px
    );
  }
`;

const LinkBlock = styled.div`
  margin-bottom: 40px;

  ul {
    margin-left: 0;
  }
`;

const Level1 = styled(Link)`
  margin-bottom: 16px !important;
`;

const TopNav = styled.div`
  position: fixed;
  display: flex;
  top: 0;
  height: 80px;
  width: 100vw;
  padding: 14px 20px 0;
  background: ${({transparent}) => (transparent ? 'transparent' : theme.textWhite)};
  z-index: 10;

  .open-button {
    color: ${({transparent}) => (transparent ? theme.textWhite : theme.textBlack)};
  }

  @media (min-width: 1440px) {
    display: none;
  }
`;

const Button = styled.button`
  position: absolute;
  top: 28px;
  right: 36px;
  background: none;
  border: none;
  font-family: Gilroy, sans-serif;
  font-size: 16px;
  line-height: 14px;
  cursor: pointer;

  &.open-button {
    border-right: 2px solid ${theme.primaryLight};
  }

  &:focus {
    outline: none;
  }
`;

const Cross = styled.span`
  &:before,
  &:after {
    position: absolute;
    right: -8px;
    top: 0px;
    content: ' ';
    height: 16px;
    width: 2px;
    background-color: var(--primary-light);
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`;

const Sidenav = ({data, location}) => {
  const {
    allMarkdownRemark: {edges},
  } = data;
  const pages = edges.filter(edge => edge.node.frontmatter.type === 'static-content');
  const demos = edges
    .filter(edge => edge.node.frontmatter.type === 'demo')
    .map(demo => ({id: demo.node.frontmatter.id, path: demo.node.frontmatter.path}));
  const createSubmenus = array =>
    array.reduce((object, edge) => {
      const level1 = edge.node.headings.find(heading => heading.depth === 1) || {};
      object[level1.value] = {
        submenus: edge.node.headings.filter(heading => heading.depth > 1),
        path: edge.node.frontmatter.path,
        id: edge.node.frontmatter.id,
      };
      return object;
    }, {});
  const menuGroups = Array.from(new Set(pages.map(edge => edge.node.frontmatter.menuGroup))).reduce(
    (obj, group) => {
      obj[group] = createSubmenus(pages.filter(edge => edge.node.frontmatter.menuGroup === group));
      return obj;
    },
    {}
  );
  const [menuOpen, setMenuOpen] = React.useState(false);
  const openMenu = () => setMenuOpen(true);
  const closeMenu = () => setMenuOpen(false);
  React.useEffect(() => {
    const handleResize = event => {
      if (event.currentTarget.innerWidth >= 1440) {
        setMenuOpen(true);
      } else {
        setMenuOpen(false);
      }
    };
    const handleEscapePress = event => {
      if (event.keyCode === 27) {
        event.preventDefault();
        setMenuOpen(false);
      }
    };
    window.addEventListener('resize', handleResize);
    window.addEventListener('keydown', handleEscapePress);
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('keydown', handleEscapePress);
    };
  });
  React.useEffect(() => {
    if (menuOpen && window.innerWidth < 1440) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
    if (!menuOpen && window.innerWidth >= 1440) {
      setMenuOpen(true);
    }
  });

  const [pagePosition, setPagePosition] = React.useState(windowObject.pageYOffset || null);
  const isHomePageTop = pagePosition === 0 && location.pathname === withPrefix('/');
  React.useEffect(() => {
    if (pagePosition === null) {
      setPagePosition(windowObject.pageYOffset);
    }
    const handleScroll = () => {
      setPagePosition(windowObject.pageYOffset);
    };
    document.addEventListener('scroll', handleScroll);
    return () => document.removeEventListener('scroll', handleScroll);
  });

  return (
    <>
      {menuOpen && (
        <Container id="menu-container">
          <Logo to="/" onClick={closeMenu} src={logo} />
          <MenuList>
            <li className="single-link" onClick={closeMenu}>
              <Link to="/">Home</Link>
            </li>
            {Object.keys(menuGroups).map(key => {
              const group = menuGroups[key];
              return (
                <LinkBlock key={key} onClick={closeMenu}>
                  <h4>{key}</h4>
                  <ul>
                    {Object.keys(group).map(level1 => {
                      const {submenus, path, id} = group[level1];
                      const {path: demoPath} = demos.find(demo => demo.id === id) || {};
                      const isCurrentRoute =
                        location.pathname.includes(path) || location.pathname.includes(demoPath);
                      return (
                        <li key={level1}>
                          <Level1 to={path}>{level1}</Level1>
                          {isCurrentRoute && (
                            <Submenu headings={submenus} pageLink={path} demoLink={demoPath} />
                          )}
                        </li>
                      );
                    })}
                  </ul>
                </LinkBlock>
              );
            })}
          </MenuList>
          <Button className="close-button" onClick={closeMenu}>
            Close
            <Cross />
          </Button>
        </Container>
      )}
      <TopNav transparent={isHomePageTop}>
        <Logo to="/" src={isHomePageTop ? logoWhite : logo} />
        <Button onClick={openMenu} className="open-button">
          Menu
        </Button>
      </TopNav>
    </>
  );
};

Sidenav.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              menuGroup: PropTypes.string,
              path: PropTypes.string,
              type: PropTypes.string,
              id: PropTypes.string,
            }),
            headings: PropTypes.arrayOf(
              PropTypes.shape({
                depth: PropTypes.number,
                value: PropTypes.string,
              })
            ),
          }),
        })
      ),
    }),
  }),
  location: PropTypes.object,
};

Sidenav.defaultProps = {
  data: {},
  location: {},
};

export default () => (
  <StaticQuery
    query={graphql`
      query {
        allMarkdownRemark(
          filter: {frontmatter: {type: {in: ["static-content", "demo"]}}}
          sort: {fields: [frontmatter___groupOrder, frontmatter___order], order: ASC}
        ) {
          edges {
            node {
              headings {
                depth
                value
              }
              frontmatter {
                menuGroup
                path
                type
                id
              }
            }
          }
        }
      }
    `}
    render={data => (
      <Location>{locationProps => <Sidenav data={data} {...locationProps} />}</Location>
    )}
  />
);
